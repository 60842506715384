import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobUpdateService {

  private jobUpdatedSource = new BehaviorSubject<boolean>(false);
  jobUpdated$ = this.jobUpdatedSource.asObservable();

  notifyJobUpdated() {
    this.jobUpdatedSource.next(true);
  }
}
