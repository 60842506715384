import { Component, EventEmitter, Inject, Output } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-preview-resume',
  templateUrl: './preview-resume.component.html',
  styleUrl: './preview-resume.component.css',
})
export class PreviewResumeComponent {
  loading: boolean = false;
  resume!: any;
  mode!: any;
  @Output() publish = new EventEmitter<void>();

  constructor(
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<PreviewResumeComponent>,
    private utilService: UtilsService
  ) {}

  ngOnInit() {
    this.resume = this.data.data2;
    this.mode = this.data.mode;
  }

  onPublish() {
    this.publish.emit();
    this.dialogRef.close();
  }

  downloadFile(base64String: string, fileName: string): void {
    this.utilService.downloadFile(base64String, fileName);
    // if (base64String) {
    //   // Extract the base64 data from the string (if it includes a prefix)
    //   const base64Parts = base64String.split(',');
    //   const base64Data =
    //     base64Parts.length > 1 ? base64Parts[1] : base64Parts[0];

    //   // Create a Blob from the base64 data
    //   const byteCharacters = atob(base64Data);
    //   const byteNumbers = new Array(byteCharacters.length);
    //   for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //   }
    //   const byteArray = new Uint8Array(byteNumbers);
    //   const blob = new Blob([byteArray], { type: 'application/pdf' }); // Change 'application/pdf' to the correct MIME type as needed

    //   // Create a link element
    //   const link = document.createElement('a');
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = fileName;

    //   // Append the link to the body
    //   document.body.appendChild(link);
    //   link.click();

    //   // Clean up and remove the link
    //   document.body.removeChild(link);
    // } else {
    //   console.error('No file available for download');
    // }
  }
}
