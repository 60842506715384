import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from '../model/apiResponse';

@Injectable({
  providedIn: 'root'
})
export class EmployerDashboardService {
  private apiUrl=environment.apiUrl;
  constructor(private http: HttpClient) { }

  getCompanyProfiles(id: string): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(`${this.apiUrl}/employer/${id}/contacts`); 
  }

  getEmployerProfile(id: string): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(`${this.apiUrl}/employer/${id}`); 
  }

  getJobs(id: string): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(`${this.apiUrl}/job/${id}/latest`); 
  }

  getInterestedCandidates(id : string) {
    return this.http.get<ApiResponse<any>>(`${this.apiUrl}/jobapplication/${id}/interested`);
  }

  getSavedCandidates(id: string) {
    return this.http.get<ApiResponse<any>>(`${this.apiUrl}/jobapplication/${id}/saved`);
  }

  getCompanyProfileList(id:any){
    return this.http.get<any>(`${this.apiUrl}/user/additionalinfo/${id}`)
    //return of(this.companyProfiles);
  }
  
  getApplicantList(searchText: string,sortColumn: string,sortDirection:string,pageIndex:number,skipCount:number,jobId:any){
    return this.http.get<any>(`${this.apiUrl}/jobApplication/applicants/${jobId}/list`, { params: new HttpParams()
      .set("skipCount",skipCount)
      .set("searchText", searchText)
      .set("sortColumn", sortColumn)
      .set("sortDirection",sortDirection)
      .set("maxResultCount",pageIndex)     
      .set("jobId", jobId.toString())
    })
  }
 
  getJobList(id:any){
    return this.http.get<any>(`${this.apiUrl}/job/${id}/list`)
  }
 
  updateStatus(id:any,status:any){
    return this.http.put<any>(`${this.apiUrl}/jobApplication/${id}/status/${status}`,{})
  }

  getEmployerJobList(params:any,employeeId:string){
    return this.http.get<any>(`${this.apiUrl}/jobapplication/${employeeId}/stats`,{params:params});
  }

  getAllSavedCandidates(skipCount:number,sortColumn: string,sortDirection:string,pageSize:number,id:any){
    return this.http.get<any>(`${this.apiUrl}/candidatesave/list/${id}/employer`,{ params: new HttpParams()
      .set("sortColumn", sortColumn)
      .set("sortDirection",sortDirection)
      .set("maxResultCount",pageSize)
      .set("skipCount",skipCount)
     });

  }

  removeSavedCandidate(ids:any){
    const body = Array.isArray(ids) ? ids : [ids];
    return this.http.delete<any>(`${this.apiUrl}/candidatesave`,{body})
  }

  getSearchCandidates(input: any): Observable<{ data: any[] }> {
    let params = new HttpParams();
    if (input?.Take != null && input?.Take != undefined) {
        params = params.append('take', input.Take.toString());
    }

    if (input?.Skip != null && input?.Skip != undefined) {
        params = params.append('skip', input.Skip.toString());
    }

    if (input?.SearchText != null && input?.SearchText != undefined) {
        params = params.append('searchText', input.SearchText.toString());
    }

    if (input?.Filter != null && input?.Filter != undefined) {
      params = params.append('filter', input.Filter.toString());
    }
      return this.http.get<{ data: any[] }>(`${this.apiUrl}/search/candidate`, {
          params: params
    });
  }

  getSearchCandidateFilters(input: any): Observable<{ data: any[] }> {
    let params = new HttpParams();
    if (input?.SearchText != null && input?.SearchText != undefined) {
        params = params.append('searchText', input.SearchText.toString());
    }

    if (input?.Filter != null && input?.Filter != undefined) {
      params = params.append('filter', input.Filter.toString());
    }
    return this.http.get<{ data: any[] }>(`${this.apiUrl}/search/candidate/filters`, {
      params: params
})
  }

  getSavedCandidatesList(id: string) {
    return this.http.get<{ data: any[] }>(`${this.apiUrl}/candidatesave/list/${id}/Employer`);
  }

  saveCandidate(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/candidatesave`, data);
  }

  deleteSavedCandidate(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/candidatesave/deletesavedcandidate`, data);
  }

  getCandidateDetailsByResumeId(id: string) {
    return this.http.get<{ data: any[] }>(`${this.apiUrl}/resume/user/${id}`);
  }

  downloadResume(id: string) {
    return this.http.get<{ data: any[] }>(`${this.apiUrl}/resume/${id}/Download`);
  }

  downloadCoverLetter(id: string) {
    return this.http.get<{ data: any[] }>(`${this.apiUrl}/resume/${id}/download/letter`);
  }
}
