<div class="dialog">
    <mat-dialog-content>
        <mat-icon class="close-icon" mat-dialog-close>close</mat-icon>
        <div class="dialog-top text-center mb-3">
            <h4>Remove Job Application?</h4>
            <span>Are you sure you want to remove this job application?</span>
            <strong class="username">{{data?.jobTitle}}</strong>
        </div>
        <div class="alert">
            <div class="warning-title">
                <mat-icon color="warn">warning</mat-icon>
                <span class="warning-text">Warning:</span>
            </div>
            <span class="warning-content">Deleting this item can erase history and deactivate some services. You will
                not be able to restore it.</span>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions class="button-group">
        <button mat-button mat-dialog-close class="btn btn-outline-grey " (click)="onCancel()">Cancel</button>
        <!-- <button mat-raised-button class="btn btn-primary disable-btn" (click)="onDisable()">Temporarily Disable</button> -->
        <button mat-raised-button color="warn" class="btn btn-primary btn-red" (click)="onRemove()">Remove</button>
    </mat-dialog-actions>
</div>