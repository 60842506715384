<div *ngIf="loading" class="spinner-overlay">
  <div class="spinner"></div>
</div>
<div class="dialog postJobModal">
  <div class="modal-header pb-1">
    <h2 class="mb-0">Resume Summary</h2>
    <mat-icon class="close-icon" mat-dialog-close>close</mat-icon>
  </div>
  <mat-dialog-content>
    <div class="modal-body">
      <h4>Fill the information</h4>

      <div class="row">
        <div class="col-lg-12 mb-3">
          <label for="summary" class="form-label">Summary</label>
          <p>{{ resume.summary }}</p>
        </div>

        <div class="col-lg-6 mb-3">
          <label for="resumeName" class="form-label"
            >Resume Name <span>*</span></label
          >
          <p>{{ resume.resumeName }}</p>
        </div>

        <div class="col-lg-6 mb-3">
          <label for="jobTitle" class="form-label">Job Title</label>
          <p>{{ resume.jobTitle }}</p>
        </div>

        <div class="col-lg-6 mb-3">
          <label for="firstName" class="form-label"
            >First Name <span>*</span></label
          >
          <p>{{ resume.firstName }}</p>
        </div>

        <div class="col-lg-6 mb-3">
          <label for="lastName" class="form-label"
            >Last Name <span>*</span></label
          >
          <p>{{ resume.lastName }}</p>
        </div>

        <div class="col-lg-6 mb-3">
          <label for="phone" class="form-label">Phone <span>*</span></label>
          <p>{{ resume.phone }}</p>
        </div>

        <div class="col-lg-6 mb-3">
          <label for="email" class="form-label">Email <span>*</span></label>
          <p>{{ resume.email }}</p>
        </div>

        <div class="col-lg-6 mb-3">
          <label for="zipCode" class="form-label"
            >Zip Code <span>*</span></label
          >
          <p>{{ resume.zipCode }}</p>
        </div>

        <div class="col-lg-12 mb-2">
          <h4>
            Resume Access Options
            <img src="assets/icons/icon-info.svg" alt="" />
          </h4>
        </div>

        <div class="col-lg-6 mb-2">
          <div class="d-flex custom-radio-btn-wrp flex-wrap">
            <div class="custom-radio-btn mb-2">
              <input type="radio" name="searchable" checked />
              <label for="search">
                <p>{{ resume.searchable }}</p>
              </label>
            </div>
          </div>
        </div>

        <div class="col-lg-6 mb-2">
          <label for="excludedEmployerIds" class="form-label"
            >Exclude Employers</label
          >
          <p>{{ resume.excludedEmployerIds.join(", ") }}</p>
        </div>

        <div class="col-lg-12">
          <h4>Other Information</h4>
        </div>

        <div class="col-lg-6 mb-3">
          <label for="employmentType" class="form-label">Employment Type</label>
          <p>{{ resume.employmentType }}</p>
        </div>

        <div class="col-lg-6 mb-3">
          <label for="jobCategory" class="form-label">Job Category</label>
          <p>{{ resume.jobCategory }}</p>
        </div>

        <div class="col-lg-6 mb-3">
          <label for="desiredAnnualSalary" class="form-label"
            >Desired Annual Salary</label
          >
          <p>{{ resume.desiredAnnualSalary }}</p>
        </div>

        <div class="col-lg-12">
          <div class="row">
            <!-- Resume Upload Section -->
            <div class="col-lg-6 mb-2">
              <div class="uploaded-content">
                <div class="uploaded-file-desc">
                  <img src="../../../../../assets/icons/icon-file.svg" alt="" />
                  <p
                    (click)="downloadFile(resume.resumebase64, resume.resume)"
                    style="cursor: pointer"
                  >
                    {{ resume.resume }}
                  </p>
                  <p>{{ resume.resumeSize }} MB</p>
                </div>
              </div>
            </div>
            <!-- Recommendation Letter Upload Section -->
            <div class="col-lg-6 mb-2" *ngIf="resume.letter">
              <div class="uploaded-content">
                <div class="uploaded-file-desc">
                  <img src="../../../../../assets/icons/icon-file.svg" alt="" />
                  <p
                    (click)="downloadFile(resume.letterBase64, resume.letter)"
                    style="cursor: pointer"
                  >
                    {{ resume.letter }}
                  </p>
                  <p>{{ resume.recommendationSize }} MB</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <mat-dialog-actions
        class="button-group d-flex justify-content-end align-items-center"
        align="end"
        *ngIf="!mode"
      >
        <button mat-button mat-dialog-close class="btn btn-outline-grey">
          Cancel
        </button>
        <button
          mat-raised-button
          color="warn"
          class="btn btn-orange"
          (click)="onPublish()"
        >
          Publish
        </button>
      </mat-dialog-actions>
    </div>
  </mat-dialog-content>
</div>
