import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BYPASS_MSAL } from './authguard/auth-interceptor';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getjobLovs(): Observable<{ data: any[] }> {
    return this.http.get<{ data: any[] }>(`${this.apiUrl}/job/lovs`);
  }

  getJobById(jobId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/job/${jobId}`, {
      context: new HttpContext().set(BYPASS_MSAL, true),
    });
  }

  jobSearch(data: any): Observable<any> {
    let params = new HttpParams();
    if (data?.EmploymentTypeFilter != null)
      data?.EmploymentTypeFilter?.forEach((id: any) => {
        params = params.append('EmploymentTypeFilter.Ids', id);
      });
    if (data?.JobCategoryFilter != null)
      data?.JobCategoryFilter?.forEach((id: any) => {
        params = params.append('JobCategoryFilter.Ids', id);
      });
    if (data.SearchText != null) {
      params = params.append('SearchText', data.SearchText);
    }
    if (data.SkipCount != null) {
      params = params.append('SkipCount', data?.SkipCount);
    }
    if (data.MaxResultCount != null) {
      params = params.append('MaxResultCount', data?.MaxResultCount);
    }
    return this.http.get<any>(`${this.apiUrl}/job/jobseeker/list`, {
      params: params,
      context: new HttpContext().set(BYPASS_MSAL, true),
    });
  }

  getSearchJobs(searchText: string): Observable<{ data: any[] }> {
    return this.http.get<{ data: any[] }>(`${this.apiUrl}/job/jobseeker/list`, {
      params: new HttpParams().set('searchText', searchText.toString()),
    });
  }

  createJob(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/job`, data);
  }

  updateJob(data: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/job`, data);
  }

  deleteJobs(data: any): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/job`, { body: data });
  }

  getEmployerDetails(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/employer/${id}`);
  }

  applyJob(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/jobapplication`, data);
  }

  getAllResumes(userId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/resume/list?id=${userId}`);
  }
  jobChangeStatus(data: any) {
    return this.http.patch<any>(
      `${this.apiUrl}/job/${data.id}/change/${data.statusId}/status`,
      {}
    );
  }
  getUserSavedJobs(userId: string, params: any) {
    return this.http.get<any>(
      `${this.apiUrl}/jobapplication/jobseeker/${userId}/saved`,
      { params: params }
    );
  }
  saveJobForJobSeeker(data: any) {
    return this.http.post<any>(`${this.apiUrl}/jobsave`, data);
  }
  removeSavedJobs(data: string[]) {
    return this.http.delete<any>(`${this.apiUrl}/jobsave`, { body: data });
  }

  getAllAppliedJobs(userId: any, data: any) {
    return this.http.get<any>(`${this.apiUrl}/jobapplication/${userId}/list`, {
      params: new HttpParams()
        .set("sortDirection", data?.sortOrder)
        .set('skipCount', data?.skipCount)
        .set("sortColumn",data?.sortColumn)
        .set(
          'JobStatusId',
          data?.searchText != undefined ? data?.searchText : ''
        )
        .set('maxResultCount', data?.pageSize),
    });
  }

  removeJobApplication(jobApplicationId: any) {
    return this.http.delete<any>(
      `${this.apiUrl}/jobapplication/${jobApplicationId}`
    );
  }

  getAllApplicationStatus() {
    return this.http.get<any>(`${this.apiUrl}/jobapplicationstatus/lovs`);
  }
  isJobSavedForJobSeeker(jobId: string, userId: string) {
    return this.http.get<any>(`${this.apiUrl}/jobsave`, {
      params: new HttpParams().set('jobId', jobId).set('userId', userId),
    });
  }

  getAllJobAlerts(userId: any, data: any) {
    let params = new HttpParams();
    params = params.append('userid', userId);
    if (data != null) {
      params = params.append('sortDirection', data?.sortOrder);
      params = params.append('skipCount', data?.skipCount);
      params = params.append('sortColumn', data?.sorting);
      params = params.append('maxResultCount', data?.pageSize);
    }
    return this.http.get<any>(`${this.apiUrl}/jobalert/list`, {
      params: params,
    });
  }

  removeJobAlert(alertId: any) {
    return this.http.delete<any>(`${this.apiUrl}/jobalert/${alertId}`);
  }

  createJobAlert(userId: any, data: any) {
    const payload = {
      UserId: userId,
      AlertName: data?.AlertName,
      SearchStringText: data?.SearchStringText,
      SearchParameterText: data?.SearchParameterText,
      CreationTime: data?.CreationTime,
    };
    return this.http.post<any>(`${this.apiUrl}/jobalert/`, payload);
  }
  statusToggleClick(alertId: any, status: any) {
    return this.http.patch<any>(
      `${this.apiUrl}/jobalert/${alertId}?disable=${status}`,
      {}
    );
  }
  frequencyToggleClick(alertId: any, frequency: any) {
    return this.http.patch<any>(
      `${this.apiUrl}/jobalert/${alertId}/frequency/${frequency}`,
      {}
    );
  }
  copyJobById(data: any[]) {
    return this.http.post(`${this.apiUrl}/job/copy`, data);
    //  return this.http.post('https://localhost:5000/api/job/copy',data)
  }

  getAllJobs(input: any): Observable<any> {
    let params = new HttpParams();
    if (input?.Take != null && input?.Take != undefined) {
      params = params.append('take', input.Take.toString());
    }
    if (input?.Skip != null && input?.Skip != undefined) {
      params = params.append('skip', input.Skip.toString());
    }
    if (input?.SearchText != null && input?.SearchText != undefined) {
      params = params.append('searchText', input.SearchText.toString());
    }
    if (input?.Filter != null && input?.Filter != undefined) {
      params = params.append('filter', input.Filter.toString());
    }
    return this.http.get(`${this.apiUrl}/search/job`, {
      params: params,
      context: new HttpContext().set(BYPASS_MSAL, true),
    });
  }
  getAllFilters(input: any): Observable<any> {
    let params = new HttpParams();
    if (input?.SearchText != null && input?.SearchText != undefined) {
      params = params.append('searchText', input.SearchText.toString());
    }
    if (input?.Filter != null && input?.Filter != undefined) {
      params = params.append('filter', input.Filter.toString());
    }
    return this.http.get(`${this.apiUrl}/search/job/filters`, {
      params: params,
      context: new HttpContext().set(BYPASS_MSAL, true),
    });
  }
  
  getJobRecommendation(input: any): Observable<any> {
    let params = new HttpParams();
    if (input?.Take != null && input?.Take != undefined) {
      params = params.append('take', input.Take.toString());
    }
    if (input?.Skip != null && input?.Skip != undefined) {
      params = params.append('skip', input.Skip.toString());
    }
    if (input?.SearchText != null && input?.SearchText != undefined) {
      params = params.append('searchText', input.SearchText.toString());
    }
    if (input?.OrderBy != null && input?.OrderBy != undefined) {
      params = params.append('orderBy', input.OrderBy.toString());
    }
    return this.http.get(`${this.apiUrl}/search/job/user/recommendations`, {
      params: params
    });
  }
  
}
