import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-job-application-removal',
  templateUrl: './job-application-removal.component.html',
  styleUrl: './job-application-removal.component.css'
})
export class JobApplicationRemovalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { jobTitle: string},
  private dialogRef: MatDialogRef<JobApplicationRemovalComponent>) {
  }

  onRemove() {
    this.dialogRef.close({ isRemove: true , isCancel:false});
  }
  onCancel() {
    this.dialogRef.close({ isCancel: true, isRemove: false  });
  }
}
