<div *ngIf="loading" class="spinner-overlay">
    <div class="spinner"></div>
</div>
<div class="dialog applyJobModal">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header pb-1 d-flex justify-content-between px-4 pt-3">
                <h2 class="mb-0">Apply for Job</h2>
                <mat-icon class="close-icon" mat-dialog-close>X</mat-icon>
            </div>
            <hr class="custom-hr">
            
            <mat-dialog-content>
                <div class="modal-body overflow-visible">
                    <div *ngIf="isJobApplyFailed" class="alert alert-warning mt-0 mb-3">
                        <label>{{applyError}}</label>
                    </div>
                    <div class="right-job-desc-box p-3 m-0">
                        <div class="right-job-desc-top border-0 mb-0 pb-0">
                            <div class="right-jobdesc-company-details">
                                <span class="job-tag-count"><strong>#</strong> {{currentJob?.jobId}}</span>
                                <h3 class="mb-1">{{currentJob?.jobTitle}}</h3>
                                <ul class="d-flex mb-2 flex-wrap">
                                    <li class="d-flex align-items-center">
                                        <img [src]="currentJob?.employer?.companyLogo" class="company-logo" alt="Logo of Company">
                                        <span>{{currentJob?.employer?.name}}</span>
                                    </li>

                                    <li class="d-flex align-items-center">
                                        <img src="assets/icons/icon-calendar.svg" alt="">
                                        <span>{{ getFormattedDate(currentJob?.postDate) || 'N/A' }}</span>
                                    </li>
                                    <li class="d-flex align-items-center">
                                        <img src="assets/icons/icon-time-progress.svg" alt="Time Progress Indicator for Expiration Date" Title="Expiration Date">
                                        <span>{{ getFormattedDate(currentJob?.expiryDate) || 'N/A' }}</span>
                                    </li>
                                </ul>
                                <div class="job-tags-wrp d-flex justify-content-between flex-wrap">
                                    <div class="job-tags-left d-flex flex-wrap">
                                        <span class="job-chip chip1 mb-2">{{ currentJob?.employmentType?.name}}</span>
                                        <span *ngFor="let industry of currentJob?.industries | keyvalue"
                                            class="job-chip chip2 color-category1 mb-2">
                                            {{ industry?.value }}
                                        </span>
                                        <span class="job-chip  chip3 mb-2"><img
                                                src="../../../assets/icons/icon-money.svg" alt="">{{getCompensationValue(currentJob)}}</span>
                                    </div>
                                    <div class="job-tags-right d-flex">
                                        <div class="reference-details">
                                            <span>ref: ({{currentJob?.jobId}})</span>
                                        </div>
                                        <div class="job-posted-date">
                                            <span><img src="../../../assets/icons/fi_clock.svg" alt="Indicating time information"> {{
                                                currentJob?.postDate | timeAgo}}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="icons-right-top d-flex" style="cursor:pointer;">
                                <figure class="tag-img-box mb-0">
                                    <img src="assets/icons/icon-tag.svg" class="m-0" (click)="saveClick()"
                                        *ngIf="!isSaved">
                                    <img src="assets/icons/icon-tag-saved.svg" class="m-0" (click)="saveClick()"
                                        *ngIf="isSaved">
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div class="select-resume-wrp mt-4">
                        <div class="select-resume-head-top mb-2">
                            <br>
                            <h6 class="mb-md-0" *ngIf="resumes.length > 0">Select your Resume</h6>
                        <h6 style="color: brown; text-align: center;font-style: italic;" class="mb-md-0" *ngIf="resumes.length === 0">No Resumes Available To Apply This Job. Please Upload</h6>
                            </div>
                            <div class="row resume-list-each me-0 row-gap-2">
                                <label *ngFor="let resume of resumes" role="button" class="resume-radio-btn col-12 col-md-6 col-lg-4 pe-0" [for]="resume.id">
                                    <input type="radio" name="plan" [id]="resume.id" [value]="resume"
                                        [(ngModel)]="selectedResume" (change)="onResumeChange(resume)"
                                        >
                                    <div class="resume-list-left w-100 d-flex align-items-center">
                                        <figure>
                                            <img src="../../../assets/icons/icon-file.svg" alt="">
                                        </figure>
                    
                                        <div class="resume-details">
                                            <h6 [title]="resume?.resumeName">{{ resume?.resumeName }}</h6>
                                            <div class="d-flex  align-items-center">
                                                <span class="file-size">{{ resume?.resumeSize }}MB</span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div class="row resume-summary-text mt-2" *ngIf="selectedResume!=null">
                                <div  class="col-12">
                                    <p><strong >Summary</strong>{{selectedResume.summary}}</p>
                                </div>
                            </div>

                        <div class="upload-resume-desc d-flex my-3">
                            <p class="mb-0">Upload new resume file or use existing resume profile.<br> New resume file
                                will be saved in My Resumes and made searchable for
                                employers.
                            </p>
                            <div class="btn-resume" style="cursor: pointer;">
                                <button class="btn btn-outline-primary" (click)="loadUploader()"><img src="assets/icons/icon-cloud-upload.svg">
                                    Upload New Resume
                                </button>
                            </div>
                            
                        </div>
                        <div class="col-lg-12" *ngIf="showUploader" #fileUploadDiv tabindex="0" >
                            <div class="row">
                                <!-- Resume Upload Section -->
                                <div>
                                    <div>                                      
                                    </div>
    
                                    <div class="file-upload-bx" *ngIf="!resumeFileName">
                                        <div class="image-upload-wrapper" (click)="onAdd()">                       
                                            <label for="resumeUpload" class="image-upload-label">Upload Resume</label>
    
                                        </div>
                                        <div class="custom-file-bx">
                                            
                                            <ng-container>
                                                <figure class="mb-0 me-2">
                                                    <img src="../../../../../assets/icons/icon-grey-upload.svg">
                                                </figure>
                                                <div class="d-flex flex-column justify-content-center">
                                                    <h5 class="mb-0">Upload New Resume</h5>                        
                                                    <div class="file-right-txt ms-0">
                                                        <h6 class="my-0">(Supports doc, docx, rtf, txt, pdf, odf up to 10 MB)</h6>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        
                                
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions class="button-group d-flex justify-content-end align-items-center" align="end">
                <button mat-button mat-dialog-close class="btn btn-outline-grey m-2">Cancel</button>
                <button 
  mat-raised-button 
  class="btn-job-apply" 
  (click)="applyJob($event)" 
  [disabled]="resumes.length===0 || selectedResume===null || isJobApplyFailed"> <!-- Use your condition here -->
  Apply
</button>

            </mat-dialog-actions>
        </div>
    </div>
</div>
