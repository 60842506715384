import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResumeService {

  private apiUrl=environment.apiUrl;

  constructor(private http:HttpClient) { }

  getEmployee():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/employer/names`)
  }

  getResumes(id:any):Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/resume/list?id=${id}`)
  }

  getResumeById(id:any):Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/resume/${id}`)
  }

  postResume(data:FormData):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}/resume`,JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json'
      }})
  }

  updateResume(data:any):Observable<any>{
    return this.http.put<any>(`${this.apiUrl}/resume`,data)
  }

  deleteResume(id:any):Observable<any>{
    return this.http.delete<any>(`${this.apiUrl}/resume/${id}`)
  }
}
