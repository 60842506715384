import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/appconstants';
import { JobService } from 'src/app/services/job.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ResumeService } from 'src/app/services/resume.service';
import { PreviewResumeComponent } from '../preview-resume/preview-resume.component';
import { EmployerDashboardService } from 'src/app/services/employer-dashboard.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-add-edit-resume',
  templateUrl: './add-edit-resume.component.html',
  styleUrls: ['./add-edit-resume.component.css'],
})
export class AddEditResumeComponent implements OnInit {
  resumeForm!: FormGroup;
  categories!: any;
  salaryRange!: any;
  type!: any;
  loading: boolean = false;
  resumeFileName: string | null = null;
  resumeFileSize: string | null = null;
  recommendationFileName: string | null = null;
  recommendationFileSize: string | null = null;
  selectedResumeFile!: any;
  selectedRecommendationFile!: any;
  user!: any;
  userId!: any;
  userDetails!: any;
  employees!: any;
  mode: boolean = false;
  editResume!: any;
  dialog!: any;
  resumeFileBlob!: any;
  recommendationFileBlob!: any;
  private readonly emailPattern = AppConstants.EMAILPATTERN;
  private readonly phonePattern = AppConstants.PH_PATTERN;
  private readonly zipCodePattern = AppConstants.ZIPCODEPATTERN;
  private readonly alphabeticPattern = AppConstants.ALPHAPATTERN;
  private readonly alphabeticNumeralPattern = AppConstants.JOBTITLEPATTERN;

  constructor(
    private fb: FormBuilder,
    private jobService: JobService,
    private profileService: ProfileService,
    private resumeService: ResumeService,
    private toastr: ToastrService,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AddEditResumeComponent>,
    private employerDashboardService: EmployerDashboardService,
    private utilService: UtilsService
  ) {}

  ngOnInit(): void {
    this.mode = this.data?.flag || false;
    this.userId = this.profileService.getUserClaims()?.userId;

    if (this.userId) {
      this.profileService.getUser(this.userId).subscribe((result: any) => {
        this.userDetails = result.data;
        this.getJobLovs();
        this.getEmployees();
        this.fillFormWithUserDetails();
      });
    }
    this.initializeForm();
    this.fetch();
  }

  fillFormWithUserDetails(): void {
    if (this.userDetails) {
      this.resumeForm.patchValue({
        userId: this.userDetails.id,
        firstName: this.userDetails.firstName,
        lastName: this.userDetails.lastName,
        phone: this.userDetails.phone,
        email: this.userDetails.email,
        zipCode: this.userDetails.zipCode,
      });
    }
  }

  initializeForm() {
    this.resumeForm = this.fb.group({
      resumeName: [
        '',
        [
          Validators.required,
          Validators.pattern(this.alphabeticNumeralPattern),
        ],
      ],
      jobTitle: ['', Validators.pattern(this.alphabeticPattern)],
      firstName: [
        { value: this.userDetails?.firstName, disabled: true },
        [Validators.required, Validators.pattern(this.alphabeticPattern)],
      ],
      lastName: [
        { value: this.userDetails?.lastName, disabled: true },
        [Validators.required, Validators.pattern(this.alphabeticPattern)],
      ],
      phone: [
        { value: this.userDetails?.phone, disabled: true },
        [Validators.required, Validators.pattern(this.phonePattern)],
      ],
      email: [
        { value: this.userDetails?.email, disabled: true },
        [Validators.required, Validators.pattern(this.emailPattern)],
      ],
      zipCode: [
        { value: this.userDetails?.zipCode, disabled: true },
        [Validators.required, Validators.pattern(this.zipCodePattern)],
      ],
      searchable: [true],
      excludedEmployerIds: [[]],
      employmentType: [null],
      jobCategory: [null],
      desiredAnnualSalary: [null],
      resume: [null, Validators.required],
      resumebase64: [null],
      letter: [null],
      letterBase64: [null],
      userId: [this.userDetails?.id],
      summary: [''],
    });
  }

  fetch() {
    if (this.mode) {
      this.resumeForm = this.fb.group({
        id: [''],
        resumeName: ['', Validators.required],
        jobTitle: [''],
        firstName: [
          { value: this.userDetails?.firstName, disabled: true },
          [Validators.required, Validators.pattern(this.alphabeticPattern)],
        ],
        lastName: [
          { value: this.userDetails?.lastName, disabled: true },
          [Validators.required, Validators.pattern(this.alphabeticPattern)],
        ],
        phone: [
          { value: this.userDetails?.phone, disabled: true },
          [Validators.required, Validators.pattern(this.phonePattern)],
        ],
        email: [
          { value: this.userDetails?.email, disabled: true },
          [Validators.required, Validators.pattern(this.emailPattern)],
        ],
        zipCode: [
          { value: this.userDetails?.zipCode, disabled: true },
          [Validators.required, Validators.pattern(this.zipCodePattern)],
        ],
        searchable: [false],
        excludedEmployerIds: [[]],
        employmentType: [''],
        jobCategory: [''],
        desiredAnnualSalary: [''],
        resume: [null, Validators.required],
        resumebase64: [null],
        letter: [null],
        letterBase64: [null],
        userId: [this.userDetails?.id],
        summary: [''],
      });
      this.loading = true;
      this.resumeService
        .getResumeById(this.data.data2.id)
        .subscribe((result: any) => {
          this.editResume = result.data;
          console.log('before', this.editResume);
          this.resumeForm.patchValue({
            id: this.editResume.id,
            firstName: this.editResume.firstName,
            lastName: this.editResume.lastName,
            email: this.editResume.email,
            zipCode: this.editResume.zipCode,
            phone: this.editResume.phone,
            userId: this.editResume.userId,
            summary: this.editResume.summary,
            resumeName: this.editResume.resumeName,
            jobTitle: this.editResume.jobTitle,
            searchable: this.editResume.searchable,
            excludedEmployerIds: this.editResume.excludedEmployerIds,
            employmentType: this.editResume.employmentType,
            jobCategory: this.editResume.jobCategory,
            desiredAnnualSalary: this.editResume.desiredAnnualSalary,
            resume: this.editResume.resume,
            letter: this.editResume.letter,
            resumebase64: this.editResume.resumebase64,
            letterBase64: this.editResume.letterBase64,
          });
          this.loading = false;
          this.resumeFileName = this.editResume.resume;
          this.recommendationFileName = this.editResume.letter;
          if (this.editResume.resumebase64) {
            console.log('Raw resumebase64:', this.editResume.resumebase64);
            const base64Parts = this.editResume.resumebase64.split(',');
            const resumebase64 =
              base64Parts.length > 1 ? base64Parts[1] : base64Parts[0];
            if (this.isValidBase64(resumebase64)) {
              this.resumeForm.patchValue({ resumebase64 });
              // Calculate file size if needed
              const byteSize = (resumebase64.length * 3) / 4;
              this.resumeFileSize = (byteSize / (1024 * 1024)).toFixed(2);
            } else {
              console.error('Invalid resumebase64 format');
              this.toastr.error(
                'The uploaded resume format is invalid. Please upload a valid file.',
                'Error'
              );
            }
          }

          // Validate and patch letterBase64
          if (this.editResume.letterBase64) {
            console.log('Raw letterBase64:', this.editResume.letterBase64);
            const base64Parts = this.editResume.letterBase64.split(',');
            const letterBase64 =
              base64Parts.length > 1 ? base64Parts[1] : base64Parts[0];
            if (this.isValidBase64(letterBase64)) {
              this.resumeForm.patchValue({ letterBase64 });
              // Calculate file size if needed
              const byteSize = (letterBase64.length * 3) / 4;
              this.recommendationFileSize = (byteSize / (1024 * 1024)).toFixed(
                2
              );
            } else {
              console.error('Invalid letterBase64 format');
              this.toastr.error(
                'The uploaded recommendation letter format is invalid. Please upload a valid file.',
                'Error'
              );
            }
          }
        });
    }
  }

  onFileSelected(event: any, controlName: string) {
    const file = event.target.files[0];
    const maxSize =
      controlName === 'resume' ? 10 * 1024 * 1024 : 2 * 1024 * 1024; // 10MB for resume, 2MB for letter
    const allowedFormats = ['doc', 'docx', 'rtf', 'txt', 'pdf', 'odf'];

    if (file) {
      const fileSize = file.size;
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (fileSize > maxSize) {
        const sizeLimit = controlName === 'resume' ? '10MB' : '2MB';
        this.toastr.error(`File size exceeds the ${sizeLimit} limit.`);
        return;
      }

      if (!fileExtension || !allowedFormats.includes(fileExtension)) {
        this.toastr.error(`File format '${fileExtension}' is not allowed.`);
        return;
      }

      // Store the file Blob for download later
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = (reader.result as string)?.split(',')[1]; // Extract the Base-64 string after the comma

        if (controlName === 'resume') {
          this.resumeForm.patchValue({
            resume: file.name,
            resumebase64: base64String, // Store only Base-64 string
          });
          this.resumeFileName = file.name;
          this.selectedResumeFile = file.name;
          this.resumeFileSize = (fileSize / (1024 * 1024)).toFixed(2);
          this.resumeFileBlob = new Blob([reader.result as ArrayBuffer], {
            type: file.type,
          }); // Store Blob for download
        }

        if (controlName === 'letter') {
          this.resumeForm.patchValue({
            letter: file.name,
            letterBase64: base64String, // Store only Base-64 string
          });
          this.recommendationFileName = file.name;
          this.selectedRecommendationFile = file.name;
          this.recommendationFileSize = (fileSize / (1024 * 1024)).toFixed(2);
          this.recommendationFileBlob = new Blob(
            [reader.result as ArrayBuffer],
            { type: file.type }
          ); // Store Blob for download
        }
      };
      reader.readAsDataURL(file);
    }
  }

  downloadResume() {
    this.employerDashboardService
      .downloadResume(this.resumeForm.value.id)
      .subscribe({
        next: (response: any) => {
          const base64String = response.data;
          //this.downloadFile(this.resumeForm.value.resume, base64String);
          this.utilService.downloadFile(
            base64String,
            this.resumeForm.value.resume
          );
          this.loading = false;
        },
      });
  }

  downloadCoverLetter() {
    this.employerDashboardService
      .downloadCoverLetter(this.resumeForm.value.id)
      .subscribe({
        next: (response: any) => {
          const base64String = response.data;
          //this.downloadFile(this.resumeForm.value.letter, base64String);
          this.utilService.downloadFile(
            base64String,
            this.resumeForm.value.resume
          );
          this.loading = false;
        },
        error: (err: any) => {
          console.log('Error occurred:', err);
        },
      });
  }

  // downloadFile(name?: string, base64?: string): void {
  //   const base64String = base64;
  //   if (base64String) {
  //     const base64Parts = base64String.split(',');
  //     const base64Data =
  //       base64Parts.length > 1 ? base64Parts[1] : base64Parts[0];

  //     const byteCharacters = atob(base64Data);
  //     const byteNumbers = new Array(byteCharacters.length);
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }
  //     const byteArray = new Uint8Array(byteNumbers);
  //     const blob = new Blob([byteArray], { type: 'application/pdf' });

  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = name || 'download';

  //     document.body.appendChild(link);
  //     link.click();

  //     document.body.removeChild(link);
  //     this.loading = false;
  //   } else {
  //     console.error('No file available for download');
  //     this.loading = false;
  //   }
  //   this.loading = false;
  // }

  isValidBase64(str: string) {
    const base64Pattern = /^[A-Za-z0-9+/]*={0,2}$/;
    return base64Pattern.test(str) && str.length % 4 === 0;
  }

  getEmployees() {
    this.resumeService.getEmployee().subscribe((result: any) => {
      this.employees = result.data;
    });
  }

  getJobLovs() {
    this.jobService.getjobLovs().subscribe(
      (resp: any) => {
        if (resp && resp.data) {
          if (
            resp.data.industries &&
            typeof resp.data.industries === 'object'
          ) {
            this.categories = Object.keys(resp.data.industries).map((key) => ({
              value: key,
              label: resp.data.industries[key],
            }));
          } else {
            console.warn('No valid categories data found.');
          }

          if (
            resp.data.employmentTypes &&
            typeof resp.data.employmentTypes === 'object'
          ) {
            this.type = Object.keys(resp.data.employmentTypes).map((key) => ({
              value: key,
              label: resp.data.employmentTypes[key],
            }));
          } else {
            console.warn('No valid employmentType data found.');
          }

          if (
            resp.data.salaryRanges &&
            typeof resp.data.salaryRanges === 'object'
          ) {
            this.salaryRange = Object.keys(resp.data.salaryRanges).map(
              (key) => ({
                value: key,
                label: resp.data.salaryRanges[key],
              })
            );
          } else {
            console.warn('No valid salaryRanges data found.');
          }
        } else {
          console.error('Invalid response structure:', resp);
        }
      },
      (error) => {
        console.error('Error fetching categories and type', error);
      }
    );
  }

  removeResumeFile() {
    const fileInput = <HTMLInputElement>document.getElementById('resumeUpload');
    if (fileInput) {
      fileInput.value = '';
    }
    this.resumeForm.patchValue({ resume: null });
    this.resumeFileName = null;
    this.resumeFileSize = null;
  }

  removeRecommendationLetter() {
    const fileInput = <HTMLInputElement>(
      document.getElementById('recommendationUpload')
    );
    if (fileInput) {
      fileInput.value = '';
    }
    this.resumeForm.patchValue({ recommendationLetter: null });
    this.recommendationFileName = null;
    this.recommendationFileSize = null;
  }

  preview() {
    let formData = { ...this.resumeForm.getRawValue() };
    const selectedEmploymentType = this.type.find(
      (emp: any) => emp.value === formData.employmentType
    )?.label;
    const selectedJobCategory = this.categories.find(
      (cat: any) => cat.value === formData.jobCategory
    )?.label;
    const employerLabels = Array.isArray(formData.excludedEmployerIds)
      ? formData.excludedEmployerIds
          .map((id: string) => {
            return this.employees.find((emp: any) => emp.id === id)?.name;
          })
          .filter((label: string | undefined) => label !== undefined)
      : [];
    const resumeFileName = this.resumeFileName;
    const recommendationFileName = this.recommendationFileName;
    const resumeFileSize = this.resumeFileSize;
    const recommendationFileSize = this.recommendationFileSize;
    const salaryRange = this.salaryRange.find(
      (cat: any) => cat.value === formData.desiredAnnualSalary
    )?.label;
    const searchableLabel = formData.searchable
      ? 'Employer can search for this resume'
      : 'Employer cannot search for this resume';

    const previewData = {
      ...formData,
      employmentType: selectedEmploymentType,
      jobCategory: selectedJobCategory,
      excludedEmployerIds: employerLabels,
      resume: resumeFileName,
      letter: recommendationFileName,
      resumeSize: resumeFileSize,
      recommendationSize: recommendationFileSize,
      desiredAnnualSalary: salaryRange,
      searchable: searchableLabel,
    };
    this.dialog = this.matDialog.open(PreviewResumeComponent, {
      data: { data2: previewData },
    });
    this.dialog.componentInstance.publish.subscribe(() => {
      this.onSubmit();
    });
  }

  onSubmit(): void {
    let formData = { ...this.resumeForm.getRawValue() };
    if (this.mode) {
      if (this.resumeForm.valid) {
        this.loading = true;
        if (!this.selectedResumeFile) {
          this.resumeForm.patchValue({
            resumebase64: null,
          });
        }
        if (!this.selectedRecommendationFile) {
          this.resumeForm.patchValue({
            letterBase64: null,
          });
        }
        formData = { ...this.resumeForm.getRawValue() };
        console.log(this.resumeForm.value);
        this.resumeService.updateResume(formData).subscribe({
          next: (response: any) => {
            const message = response.success
              ? 'Resume updated successfully!'
              : response.message || 'Resume update encountered issues.';
            this.toastr[response.success ? 'success' : 'error'](message);
            this.dialogRef.close();
          },
          error: (err) =>
            this.toastr.error(
              `Error: ${err.error?.title || 'An unexpected error occurred.'}`
            ),
          complete: () => (this.loading = false),
        });
      } else {
        console.log('Form is invalid');
      }
    } else {
      if (this.resumeForm.valid) {
        this.loading = true;
        console.log(formData);
        this.resumeService.postResume(formData).subscribe({
          next: (response: any) => {
            if (response.data === 'You can only upload up to 3 resumes') {
              this.toastr.error(`Error: ${response.data}`);
            } else {
              const message = response.success
                ? 'Resume added successfully!'
                : response.message || 'Resume adding encountered issues.';
              this.toastr[response.success ? 'success' : 'error'](message);
              this.dialogRef.close();
            }
          },
          error: (err) =>
            this.toastr.error(
              `Error: ${err.error?.title || 'An unexpected error occurred.'}`
            ),
          complete: () => (this.loading = false),
        });
      } else {
        this.resumeForm.markAllAsTouched();
        return;
      }
    }
  }
}
