import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/appconstants';
import { JobUpdateService } from 'src/app/services/job-update.service';
import { JobService } from 'src/app/services/job.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ResumeService } from 'src/app/services/resume.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddEditResumeComponent } from '../dashboard/job-seeker-dashboard/add-edit-resume/add-edit-resume.component';
import { DateUtilsService } from '../../utils/date-utils.service';

@Component({
  selector: 'app-apply-job',
  templateUrl: './apply-job.component.html',
  styleUrl: './apply-job.component.css'
})

export class ApplyJobComponent {
  currentJob: any;
  isSaved: boolean = false;
  userId: any;
  resumes: any[] = [];
  selectedResume: any;
  jobSaveId: any;
  isLoggedIn: any;
  loading: boolean = false; // Loading state
  applyError: string = "";
  isResumesAvailable = true;
  resumeForm!: FormGroup;
  categories!: any;
  salaryRange!: any;
  type!: any;
  resumeFileName: string | null = null;
  resumeFileSize: string | null = null;
  recommendationFileName: string | null = null;
  recommendationFileSize: string | null = null;
  selectedResumeFile!: any;
  selectedRecommendationFile!: any;
  user!: any;
  userDetails!: any;
  employees!: any;
  mode: boolean = false;
  editResume!: any;
  dialog!: any;
  resumeFileBlob!: any;
  recommendationFileBlob!: any;
  resumeDialogRef!: any;
  isJobApplyFailed = false;
  showUploader: boolean = false;
  @ViewChild('fileUploadDiv') fileUploadDiv!: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { id: string },
    private dialogRef: MatDialogRef<ApplyJobComponent>,
    private jobSearchService: JobService,
    private profileService: ProfileService,
    private jobService: JobService,
    private toastr: ToastrService,
    private jobUpdateService: JobUpdateService,
    private resumeService: ResumeService,
    private matDialog: MatDialog,
    private dateUtilsService: DateUtilsService,
    private fb: FormBuilder) {
  }
  ngOnInit(): void {
    const loginStatus = localStorage.getItem(AppConstants.LOGINSTATUS);
    this.isLoggedIn = loginStatus == AppConstants.TRUE ? true : false;
    this.getJobDetails(this.data.id);
    this.userId = this.profileService.getUserClaims()?.userId;
    this.getAllResumesUploaded();
    this.selectedResume = null;
  }
  loadUploader() {
    this.showUploader = true; // Toggle visibility
    //this.fileUploadDiv.nativeElement.focus();
    setTimeout(() => { this.fileUploadDiv.nativeElement.focus(); }, 0); 
  }
  getJobDetails(jobId: string) {
    this.jobService.getJobById(jobId).subscribe((result: any) => {  
      this.currentJob = result.data;
      this.loading = false;
      if (this.isLoggedIn) {
        this.jobService.isJobSavedForJobSeeker(this.data.id, this.userId)
          .subscribe((jobSaveResult: any) => {
            this.isSaved = jobSaveResult.data.isJobSaved;
            this.jobSaveId = jobSaveResult.data.jobSaveId;
            this.loading = false;
          });
      }
    });
  }
  onAdd() {
    this.resumeDialogRef = this.matDialog.open(AddEditResumeComponent);
    this.resumeDialogRef.afterClosed().subscribe((result: any) => {
      this.getAllResumesUploaded();
    })
  }
  getAllResumesUploaded() {
    if (this.userId) {
      this.loading = true;
      this.jobSearchService.getAllResumes(this.userId).subscribe({
        
        next: (data) => {

          this.resumes = data?.data;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error fetching resumes:', error);
        },
      });

    }

  }


  addHttpIfMissing(url: string): string {
    if (!/^https?:\/\//i.test(url)) {
      return 'http://' + url;
    }
    return url;
  }

  applyJob(event: MouseEvent) {
    this.loading = true;
    let dataToSend = {
      jobId: this.currentJob?.id,
      resumeId: this.selectedResume?.id,
      userId: this.userId,
      jobApplicationStausId: '46D411CD-99BA-4C2F-81DA-11A75A05A2E2'
    }
    this.jobSearchService.applyJob(dataToSend).subscribe(response => {
      this.loading = false;
      if (response?.success) {
        this.jobUpdateService.notifyJobUpdated();
        this.toastr.success(
          AppConstants.JOB_APPLY_SUCCESS_MSG, '',
          {
            progressBar: true,
            closeButton: true,
            positionClass: 'toast-top-right',
            enableHtml: true
          }
        );
        if (this.currentJob?.jobUrl != null) {
          event.preventDefault();
          this.dialogRef.close();
          window.open(this.addHttpIfMissing(this.currentJob?.jobUrl), '_blank');
        }
        else
          this.dialogRef.close();
      }
      else {
        this.isJobApplyFailed = true;
        this.applyError = response.message;
      }
    })
  }
  saveClick() {
    if (!this.isSaved) {

      var requestData = {
        jobId: this.data.id,
        userId: this.userId
      };

      this.jobService.saveJobForJobSeeker(requestData)
        .subscribe((result: any) => {
          if (result.success) {
            this.jobSaveId = result.data.jobSaveId;
            this.isSaved = !this.isSaved;
          }
        });
    } else {
      var removeSavedJobArray = [];
      removeSavedJobArray.push(this.jobSaveId);
      this.jobService.removeSavedJobs(removeSavedJobArray)
        .subscribe((result: any) => {
          if (result.success) {
            this.isSaved = false;
          }
        });
    }
  }

  onResumeChange(id: string) {
    this.selectedResume = id;
  }
  getCompensationValue(currentJobData: any) {
    if (!currentJobData) return currentJobData;
    
    let compensationValue = '';
    if (currentJobData?.compensation?.typeName == AppConstants.SALARY)
      compensationValue = AppConstants.DOLLAR + currentJobData?.compensationMin + '- ' + AppConstants.DOLLAR + currentJobData?.compensationMax + AppConstants.PERYEAR;
    else if (currentJobData?.compensation?.typeName == AppConstants.HOURLY)
      compensationValue = AppConstants.DOLLAR + currentJobData?.compensationMin + AppConstants.PERHOUR;
    return compensationValue;
  }
  onFileSelected(event: any, controlName: string) {
    const file = event.target.files[0];
    const maxSize = controlName === 'resume' ? 10 * 1024 * 1024 : 2 * 1024 * 1024; // 10MB for resume, 2MB for letter
    const allowedFormats = ['doc', 'docx', 'rtf', 'txt', 'pdf', 'odf'];

    if (file) {
      const fileSize = file.size;
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (fileSize > maxSize) {
        const sizeLimit = controlName === 'resume' ? '10MB' : '2MB';
        this.toastr.error(`File size exceeds the ${sizeLimit} limit.`);
        return;
      }

      if (!fileExtension || !allowedFormats.includes(fileExtension)) {
        this.toastr.error(`File format '${fileExtension}' is not allowed.`);
        return;
      }

      // Store the file Blob for download later
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = (reader.result as string)?.split(',')[1]; // Extract the Base-64 string after the comma

        if (controlName === 'resume') {
          this.resumeForm.patchValue({
            resume: file.name,
            resumebase64: base64String // Store only Base-64 string
          });
          this.resumeFileName = file.name;
          this.selectedResumeFile = file.name;
          this.resumeFileSize = (fileSize / (1024 * 1024)).toFixed(2);
          this.resumeFileBlob = new Blob([reader.result as ArrayBuffer], { type: file.type }); // Store Blob for download
        }

        if (controlName === 'letter') {
          this.resumeForm.patchValue({
            letter: file.name,
            letterBase64: base64String // Store only Base-64 string
          });
          this.recommendationFileName = file.name;
          this.selectedRecommendationFile = file.name;
          this.recommendationFileSize = (fileSize / (1024 * 1024)).toFixed(2);
          this.recommendationFileBlob = new Blob([reader.result as ArrayBuffer], { type: file.type }); // Store Blob for download
        }
      };
      reader.readAsDataURL(file);
    }
  }
  getFormattedDate(date: any): string {
    return date ? this.dateUtilsService.convertToMMDDYYYY(date) : '';
  }
  
  onSubmit(): void {
    let formData = { ...this.resumeForm.getRawValue() };
    if (this.mode) {
      if (this.resumeForm.valid) {
        this.loading = true;
        if (!this.selectedResumeFile) {
          this.resumeForm.patchValue({
            resumebase64: null
          })
        }
        if (!this.selectedRecommendationFile) {
          this.resumeForm.patchValue({
            letterBase64: null
          })
        }
        formData = { ...this.resumeForm.getRawValue() };
        console.log(this.resumeForm.value)
        this.resumeService.updateResume(formData).subscribe({
          next: (response: any) => {
            const message = response.success
              ? 'Resume updated successfully!'
              : response.message || 'Resume update encountered issues.';
            this.toastr[response.success ? 'success' : 'error'](message);
            this.dialogRef.close();
          },
          error: (err) =>
            this.toastr.error(
              `Error: ${err.error?.title || 'An unexpected error occurred.'}`
            ),
          complete: () =>
            this.loading = false
        })
      } else {
        console.log("Form is invalid");
      }
    }
    else {
      if (this.resumeForm.valid) {

        this.loading = true;
        console.log(formData)
        this.resumeService.postResume(formData).subscribe({
          next: (response: any) => {
            if (response.data === "You can only upload up to 3 resumes") {
              this.toastr.error(`Error: ${response.data}`);
            }
            else {
              const message = response.success
                ? 'Resume added successfully!'
                : response.message || 'Resume adding encountered issues.';
              this.toastr[response.success ? 'success' : 'error'](message);
              this.dialogRef.close();
            }

          },
          error: (err) =>
            this.toastr.error(
              `Error: ${err.error?.title || 'An unexpected error occurred.'}`
            ),
          complete: () => this.loading = false
        })
      } else {
        this.resumeForm.markAllAsTouched();
        return;
      }
    }

  }
}
