<div *ngIf="loading" class="spinner-overlay">
  <div class="spinner"></div>
</div>
<div class="dialog postJobModal">
  <div class="modal-header pb-1">
    <h2 class="mb-0">{{ mode ? "Edit Resume Summary" : "Resume Summary" }}</h2>
    <mat-icon class="close-icon" mat-dialog-close>close</mat-icon>
  </div>
  <mat-dialog-content>
    <div class="modal-body">
      <h4>Fill the information</h4>
      <form [formGroup]="resumeForm">
        <div class="row">
          <div class="col-lg-12 mb-3">
            <label for="summary" class="form-label">Summary</label>
            <textarea
              class="form-control"
              formControlName="summary"
              cols="30"
              rows="5"
              maxlength="250"
              placeholder="Enter Summary (In 250 characters or less highlight your key skills and experience.)"
            ></textarea>
          </div>

          <div class="col-lg-6 mb-3">
            <label for="resumeName" class="form-label"
              >Resume Name <span>*</span></label
            >
            <input
              type="text"
              class="form-control"
              formControlName="resumeName"
              placeholder="Enter Resume Name"
              [ngClass]="{
                'is-invalid':
                  resumeForm.get('resumeName')?.invalid &&
                  (resumeForm.get('resumeName')?.dirty ||
                    resumeForm.get('resumeName')?.touched)
              }"
            />
            <div
              *ngIf="
                resumeForm.get('resumeName')?.invalid &&
                resumeForm.get('resumeName')?.touched
              "
              class="text-danger"
            >
              <div *ngIf="resumeForm.get('resumeName')?.errors?.['required']">
                Resume Name is required
              </div>
              <div *ngIf="resumeForm.get('resumeName')?.errors?.['pattern']">
                Only alphabets, numbers, special characters and spaces are
                allowed
              </div>
            </div>
          </div>

          <div class="col-lg-6 mb-3">
            <label for="jobTitle" class="form-label">Job Title</label>
            <input
              type="text"
              class="form-control"
              formControlName="jobTitle"
              placeholder="Enter Job Title"
            />
            <div
              *ngIf="resumeForm.get('jobTitle')?.invalid"
              class="text-danger"
            >
              <div *ngIf="resumeForm.get('jobTitle')?.errors?.['pattern']">
                Only alphabets and spaces are allowed
              </div>
            </div>
          </div>

          <div class="col-lg-6 mb-3">
            <label for="firstName" class="form-label"
              >First Name <span>*</span></label
            >
            <input
              type="text"
              id="firstName"
              class="form-control"
              formControlName="firstName"
              placeholder="Enter First Name"
              [ngClass]="{
                'is-invalid':
                  resumeForm.get('firstName')?.invalid &&
                  (resumeForm.get('firstName')?.dirty ||
                    resumeForm.get('firstName')?.touched)
              }"
            />
            <div
              *ngIf="resumeForm.get('firstName')?.invalid"
              class="text-danger"
            >
              <div *ngIf="resumeForm.get('firstName')?.errors?.['required']">
                First Name is required
              </div>
              <div *ngIf="resumeForm.get('firstName')?.errors?.['pattern']">
                Only alphabets and spaces are allowed
              </div>
            </div>
          </div>

          <div class="col-lg-6 mb-3">
            <label for="lastName" class="form-label"
              >Last Name <span>*</span></label
            >
            <input
              type="text"
              id="lastName"
              class="form-control"
              formControlName="lastName"
              placeholder="Enter Last Name"
              [ngClass]="{
                'is-invalid':
                  resumeForm.get('lastName')?.invalid &&
                  (resumeForm.get('lastName')?.dirty ||
                    resumeForm.get('lastName')?.touched)
              }"
            />
            <div
              *ngIf="resumeForm.get('lastName')?.invalid"
              class="text-danger"
            >
              <div *ngIf="resumeForm.get('lastName')?.errors?.['required']">
                Last Name is required
              </div>
              <div *ngIf="resumeForm.get('lastName')?.errors?.['pattern']">
                Only alphabets and spaces are allowed
              </div>
            </div>
          </div>

          <div class="col-lg-6 mb-3">
            <label for="phone" class="form-label">Phone <span>*</span></label>
            <input
              type="text"
              class="form-control"
              appPhoneMask
              formControlName="phone"
              placeholder="Enter Phone Number"
              [ngClass]="{
                'is-invalid':
                  resumeForm.get('phone')?.invalid &&
                  (resumeForm.get('phone')?.dirty ||
                    resumeForm.get('phone')?.touched)
              }"
            />
            <div
              *ngIf="resumeForm.get('phone')?.errors?.['required']"
              class="text-danger"
            >
              Phone is required
            </div>
            <div
              *ngIf="resumeForm.get('phone')?.errors?.['pattern']"
              class="text-danger"
            >
              Invalid phone number format
            </div>
          </div>

          <div class="col-lg-6 mb-3">
            <label for="email" class="form-label">Email <span>*</span></label>
            <input
              type="email"
              id="email"
              class="form-control"
              formControlName="email"
              placeholder="Enter Email"
              [ngClass]="{
                'is-invalid':
                  resumeForm.get('email')?.invalid &&
                  (resumeForm.get('email')?.dirty ||
                    resumeForm.get('email')?.touched)
              }"
            />
            <div *ngIf="resumeForm.get('email')?.invalid" class="text-danger">
              Email is required
            </div>
          </div>

          <div class="col-lg-6 mb-3">
            <label for="zipCode" class="form-label"
              >Zip Code <span>*</span></label
            >
            <input
              type="text"
              class="form-control"
              formControlName="zipCode"
              placeholder="Enter Zip Code"
              [ngClass]="{
                'is-invalid':
                  resumeForm.get('zipCode')?.invalid &&
                  (resumeForm.get('zipCode')?.dirty ||
                    resumeForm.get('zipCode')?.touched)
              }"
            />
            <div *ngIf="resumeForm.get('zipCode')?.invalid" class="text-danger">
              Zip Code is required
            </div>
          </div>

          <div class="col-lg-12 mb-2">
            <h4>
              Resume Access Options
              <img src="../../../../../assets/icons/icon-info.svg" alt="" />
            </h4>
          </div>

          <div class="col-lg-6 mb-2">
            <div class="d-flex custom-radio-btn-wrp flex-wrap">
              <div class="custom-radio-btn mb-2">
                <input
                  type="radio"
                  name="searchable"
                  id="search"
                  formControlName="searchable"
                  [value]="true"
                />
                <label for="search">Employer can search for this resume</label>
              </div>
              <div class="custom-radio-btn mb-2">
                <input
                  type="radio"
                  name="searchable"
                  id="notsearch"
                  formControlName="searchable"
                  [value]="false"
                />
                <label for="notsearch"
                  >Employer cannot search for this resume</label
                >
              </div>
            </div>
          </div>

          <div class="col-lg-6 mb-2">
            <label for="excludedEmployerIds" class="form-label"
              >Exclude Employers</label
            >
            <div class="srch-bx">
              <mat-form-field appearance="fill" class="w-100">
                <mat-select
                  multiple
                  placeholder="Exclude Resumes from..."
                  formControlName="excludedEmployerIds"
                >
                  <mat-option
                    *ngFor="let employee of employees"
                    [value]="employee.id"
                    >{{ employee.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <img src="assets/icons/search.svg" alt="" />
            </div>
          </div>

          <div class="col-lg-12">
            <h4>Other Information</h4>
          </div>

          <div class="col-lg-6 mb-3">
            <label class="form-label">Employment Type</label>
            <mat-form-field appearance="fill" class="w-100">
              <mat-select
                placeholder="Select Employment Type"
                id="employmentType"
                formControlName="employmentType"
              >
                <mat-option
                  *ngFor="let empType of type"
                  [value]="empType.value"
                  >{{ empType.label }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-lg-6 mb-3">
            <label class="form-label">Job Category</label>
            <mat-form-field appearance="fill" class="w-100">
              <mat-select
                placeholder="Select Job Category"
                id="jobCategory"
                formControlName="jobCategory"
              >
                <mat-option
                  *ngFor="let category of categories"
                  [value]="category.value"
                  >{{ category.label }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-lg-6 mb-3">
            <label for="desiredAnnualSalary" class="form-label"
              >Desired Annual Salary</label
            >
            <mat-form-field appearance="fill" class="w-100">
              <mat-select
                placeholder="Select Desired Annual Salary"
                formControlName="desiredAnnualSalary"
              >
                <mat-option
                  *ngFor="let range of salaryRange"
                  [value]="range.value"
                  >{{ range.label }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-lg-12">
            <div class="row">
              <!-- Resume Upload Section -->
              <div class="col-lg-6 mb-2">
                <div
                  *ngIf="resumeFileName"
                  class="uploaded-content"
                  (click)="downloadResume()"
                >
                  <div class="delete-file">
                    <img
                      src="../../../../../assets/icons/icon-red-delete.svg"
                      alt=""
                      (click)="removeResumeFile()"
                    />
                  </div>
                  <div class="uploaded-file-desc">
                    <img
                      src="../../../../../assets/icons/icon-file.svg"
                      alt=""
                    />
                    <p>{{ resumeFileName }}</p>
                    <p>{{ resumeFileSize }} MB</p>
                  </div>
                </div>

                <div class="file-upload-bx" *ngIf="!resumeFileName">
                  <div class="image-upload-wrapper">
                    <input
                      type="file"
                      class="image-upload-input"
                      id="resumeUpload"
                      (change)="onFileSelected($event, 'resume')"
                    />
                    <label for="resumeUpload" class="image-upload-label"
                      >Upload Resume</label
                    >
                  </div>
                  <div class="custom-file-bx">
                    <!-- Show this div only when no resume file is uploaded -->
                    <ng-container *ngIf="!resumeFileName">
                      <figure class="mb-3">
                        <img
                          src="../../../../../assets/icons/icon-grey-upload.svg"
                          alt=""
                        />
                      </figure>
                      <h5 class="mb-0">Upload New Resume <span>*</span></h5>
                      <div class="file-right-txt">
                        <p class="mb-0">
                          <span>Browse File</span> or drop here
                        </p>
                        <h6>
                          (Supports doc, docx, rtf, txt, pdf, odf up to 10 MB)
                        </h6>
                      </div>
                    </ng-container>
                  </div>
                  <div
                    class="text-danger"
                    *ngIf="
                      resumeForm.controls['resume'].touched &&
                      resumeForm.controls['resume'].invalid
                    "
                  >
                    Uploading a resume is mandatory
                  </div>
                </div>
              </div>

              <!-- Recommendation Letter Upload Section -->
              <div class="col-lg-6 mb-2">
                <div
                  *ngIf="recommendationFileName"
                  class="uploaded-content"
                  (click)="downloadCoverLetter()"
                >
                  <div class="delete-file">
                    <img
                      src="../../../../../assets/icons/icon-red-delete.svg"
                      alt=""
                      (click)="removeRecommendationLetter()"
                    />
                  </div>
                  <div class="uploaded-file-desc">
                    <img
                      src="../../../../../assets/icons/icon-file.svg"
                      alt=""
                    />
                    <p>{{ recommendationFileName }}</p>
                    <p>{{ recommendationFileSize }} MB</p>
                  </div>
                </div>
                <div class="file-upload-bx" *ngIf="!recommendationFileName">
                  <div class="image-upload-wrapper">
                    <input
                      type="file"
                      class="image-upload-input"
                      id="recommendationUpload"
                      (change)="onFileSelected($event, 'letter')"
                    />
                    <label for="recommendationUpload" class="image-upload-label"
                      >Upload Recommendation Letter</label
                    >
                  </div>
                  <div class="custom-file-bx">
                    <ng-container *ngIf="!recommendationFileName">
                      <figure class="mb-3">
                        <img
                          src="../../../../../assets/icons/icon-grey-upload.svg"
                          alt=""
                        />
                      </figure>
                      <h5 class="mb-0">Upload Recommendation Letter</h5>
                      <div class="file-right-txt">
                        <p class="mb-0">
                          <span>Browse File</span> or drop here
                        </p>
                        <h6>
                          (Supports doc, docx, rtf, txt, pdf, odf up to 2 MB)
                        </h6>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <mat-dialog-actions
          class="button-group d-flex justify-content-end align-items-center"
          align="end"
        >
          <button mat-button mat-dialog-close class="btn btn-outline-grey">
            Cancel
          </button>
          <button mat-button class="btn btn-primary" (click)="preview()">
            Preview
          </button>
          <button
            mat-raised-button
            color="warn"
            class="btn btn-orange"
            type="submit"
            (click)="onSubmit()"
          >
            {{ mode ? "Update" : "Publish" }}
          </button>
        </mat-dialog-actions>
      </form>
    </div>
  </mat-dialog-content>
</div>
